#login-twofa-selection {
  .type-holder {
    .circle {
      width: 128px;
      height: 128px;
      background-color: $color-success;
      border-radius: 50%;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      -ms-transform: translateX(-50%);

      svg {
        position: absolute;
        width: 72px;
        height: 72px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);

        path {
          fill: $card-background;
        }
        g {
          fill: $card-background;
        }
      }
    }

    .btn {
      width: 100%;
    }

    h6 {
      width: 100%;
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
    }

    p {
      width: 100%;
      text-align: center;
      font-size: 0.9rem;
    }
  }
}
