.app-login-global-holder {
  z-index: 2;
  min-height: 300px;
  width: 600px;
  padding: 20px;
  position: absolute;
  top: calc(50% - 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  opacity: 0;
  animation-fill-mode: forwards;

  h2 {
    width: 100%;
    font-weight: 300;
  }

  p {
    width: 100%;
    font-weight: 300;
  }

  .type-holder {
    .card {
      margin: 0 10px;
      height: 100%;
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;

      & > * {
        order: 0;
        margin: 0 0 20px 0;
        padding: 0;
      }

      a {
        margin: 0;
      }
    }
  }

  .link-button-holder {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    & > * {
      order: 1;
      margin: 0;
    }
  }
}
