#login-gauth-qrcode {
  width: 752px;

  .btn {
    width: 40%;
  }

  .lefright {
    display: flex;
    flex-direction: row;

    & > * {
      order: 1;
    }

    .content {
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    h3 {
      font-weight: 300;
    }
  }

  .store-buttons {
    padding-top: 20px;
    border-top: 1px solid #ccc;
    margin-top: 20px;
    font-size: 0.9rem;
    text-align: center;
    font-weight: 400;
    align-items: center;
    display: flex;
    flex-direction: column;

    .inner {
      width: 50%;
      order: 1;
    }

    .link-button-holder {
      justify-content: space-evenly;

      a {
        &.icon {
          &.apple {
            width: calculated-width(184px, 68px, 48px);
            height: 48px;
          }
          &.google {
            width: calculated-width(204px, 68px, 48px);
            height: 48px;
          }

          svg {
            width: 100%;
            height: 100%;

            g, path {
              fill: $color-darkest;
            }
          }
        }
      }
    }
  }
}
