@media only screen and (max-width: 800px) {
}

@media only screen and (max-width: 500px) {
  .app-login-global-holder {
    width: calc(100% - 20px);
    top: calc(50% - 20px);
    padding: 10px;

    h2 {
      font-size: 1.2rem;
    }

    p {
      font-size: 0.8rem;
    }

    .type-holder {
      .card > * {
        margin: 0 0 10px 0;
      }
    }
  }

  #header-holi-logo {
    margin-left: 20px;
  }

  #login-twofa-selection {
    .type-holder {
      .card {
        margin: 0 5px;
        padding: 5px;
      }

      h6 {
        font-size: 0.8rem;
      }

      p {
        font-size: 0.8rem;
      }

      .circle {
        width: 44px;
        height: 44px;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .btn {
        min-width: 20px;
        height: 30px;
        font-size: 76%;
      }
    }
  }

  #login-gauth-code {
    width: 300px;
    top: calc(50%);
  }

  #login-phone-missing {
    width: 300px;
    top: calc(50%);

    a {
      font-size: 0.8rem;
    }
  }

  #login-sms-code {
    width: 300px;
    top: calc(50%);

    a {
      font-size: 0.8rem;
    }
  }

  #login-warning {
    width: 300px;
    top: calc(50%);

    a {
      font-size: 0.8rem;
    }
  }

  #login-gauth-qrcode {
    width: 300px;
    top: calc(50% - 10px);

    h3 {
      font-size: 1rem;
    }

    .btn {
      width: 40%;
      height: 30px;
      font-size: 76%;
      min-width: 80px;
    }

    .link-button-holder {
      margin-top: 10px;

      a {
        font-size: 0.8rem;
      }
    }

    .qrcode {
      img {
        width: 70px;
      }
    }

    .store-buttons {
      .inner {
        width: 90%;
      }
    }
  }
}
