@function size-calculator($original, $ratio) {
  @return floor($original * $ratio);
}

@function calculated-width($width, $height, $known-height) {
  @return floor($width * $known-height / $height);
}

@keyframes presentCenteredDomWithAnimation {
  from {
    transform: translate3d(-50%, -30%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
  }
}

@keyframes forwardRightToLeftWithAnimation {
  from {
    transform: translate(0%, 0%);
    opacity: 0;
  }
  to {
    transform: translate(-10%, 0%);
    opacity: 1;
  }
}

$fonts-url: "/fonts";

$font-stack: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$background-color: white;

//<editor-fold desc="Link">
$link-color-passive: #0f88d2;
$link-color-active: #10c0d2;
//</editor-fold>

//<editor-fold desc="Colors">
$color-darkest: #43435f;
$color-darker: #656590;
$color-dark: #8b8b8b;
$color-light: #b2b2b2;
$color-lighter: #c7c7c7;
$color-lightest: #e3e3e3;
$color-near-white: #f7f7f7;
$color-near-black: #0a0a0a;

$color-success: #2ed573;
$color-warning: #ffac44;
$color-error: #ff4757;

$color-green: #61BD4F;
$color-yellow: #F2D600;
$color-orange: #FFAB4A;
$color-red: #EB5A46;
$color-purple: #C377E0;
$color-blue: #0079BF;

$color-green-dark: darken(#61BD4F, 10);
$color-yellow-dark: darken(#F2D600, 10);
$color-orange-dark: darken(#FFAB4A, 10);
$color-red-dark: darken(#EB5A46, 10);
$color-purple-dark: darken(#C377E0, 10);
$color-blue-dark: darken(#0079BF, 10);

$color-green-light: lighten(#61BD4F, 10);
$color-yellow-light: lighten(#F2D600, 10);
$color-orange-light: lighten(#FFAB4A, 10);
$color-red-light: lighten(#EB5A46, 10);
$color-purple-light: lighten(#C377E0, 10);
$color-blue-light: lighten(#0079BF, 10);
$color-light-border: #DCE2EE;
//</editor-fold>

//<editor-fold desc="Input">
$input-text: black;
$input-placeholder: #3b3b3b;
$input-background: white;
$input-border: 1px solid #c7c7c7;
//</editor-fold>

//<editor-fold desc="Card">
$card-background: white;
$card-border: 1px solid $color-light-border;
$card-low-shadow: 0 3px 8px 0 rgba(35, 34, 135, 0.05);
$card-high-shadow: 0 5px 20px 0 rgba(35, 34, 135, 0.1);
$card-higher-shadow: 0 8px 30px 1px rgba(35, 34, 135, 0.1);
$card-highest-shadow: 0 16px 40px 2px rgba(35, 34, 135, 0.1);
//</editor-fold>

//<editor-fold desc="Buttons">
$button-default-shadow: 0 2px 10px 0 rgba(35, 34, 135, 0.1);
$button-default-border: 1px solid rgba(35, 34, 135, 0.1);
$button-default-background: white;
$button-default-color: #3d3bee;

$button-primary-background: #3d3bee;
$button-primary-color: white;

$button-secondary-background: white;
$button-secondary-color: #3d3bee;
$button-secondary-border-color: #3d3bee;
$button-secondary-border: 1px solid $button-secondary-border-color;

$button-primary-dark: 20;
$button-primary-darker: 40;

$button-secondary-dark: 5;
$button-secondary-darker: 10;
//</editor-fold>

//<editor-fold desc="Dashboard">
$dashboard-header-background: white;
$dashboard-header-name: rgb(48, 48, 54);
$dashboard-header-avatar-border: 2px solid rgb(211, 213, 224);

$dashboard-bar-background: white;
$dashboard-bar-border: 1px solid #fafafa;
$dashboard-bar-shadow: 0 -5px 20px 0 rgba(35, 34, 135, 0.1);
$dashboard-bar-color: #535376;
$dashboard-bar-hover-color: #e7eefc;

$dashboard-box-background: white;
$dashboard-box-shadow: 0 0 0 0 white;
$dashboard-box-border: 1px solid $color-light-border;
$dashboard-box-moving-border: 1px dashed #eec13b;
$dashboard-box-moving-background: #fffef2;
$dashboard-box-waiting-border: 1px solid #fbe9d4;
$dashboard-box-waiting-background: white;

$dashboard-popup-shadow: 0 5px 20px 0 rgba(35, 34, 135, 0.5);
$inset-shadow: inset 0 2px 0 transparentize($color-darkest, 0.6), 0 2px 0 transparentize($color-lighter, 0.6);
$inset-shadow-small: inset 0 1px 0 transparentize($color-darkest, 0.6), 0 1px 0 transparentize($color-lighter, 0.6);
//</editor-fold>

//<editor-fold desc="Editorial">
$editor-border: 1px solid #e3e3e3;
$editor-border-dashed: 1px dashed #e3e3e3;
$editor-fill: #f7faff;

$editor-drag-border: 1px solid #f9c173;
$editor-drag-border-dashed: 1px dashed #f9c173;
$editor-drag-fill: #f9e2b6;
//</editor-fold>

// Tasks Manager
$color-star-gray: #8D9199;
$color-star-yellow: $color-warning;

// Calendar
$day-width: (100% / 7);
$line-gap: 50px;
$line-height: 40px;
$min-line-height: 132px;
$color-bg-green: #D9FFE9;
$color-bg-yellow: #FFF5D9;
$color-bg-orange: #FFE3D9;
$color-bg-red: #FFD9DC;
$color-bg-purple: #EDD9FF;
$color-bg-blue: #D9F1FF;
$color-names: 'green', 'yellow', 'orange', 'red', 'purple', 'blue';
$color-list: $color-green, $color-yellow, $color-orange, $color-red, $color-purple, $color-blue;
$color-bg-list: $color-bg-green, $color-bg-yellow, $color-bg-orange, $color-bg-red, $color-bg-purple, $color-bg-blue;

@mixin tableLineHeights($start, $end) {
  @for $i from $start through $end {
    &.has-line-#{$i} {
      height: ($min-line-height + ($line-height * ($i - 2)));
    }
  }
}

@mixin dayLoop($start, $end) {
  @for $i from $start through $end {
    &.day-#{$i} {
      width: ($day-width * $i);
    }
  }
}

@mixin lineLoop($start, $end) {
  @for $i from $start through $end {
    &.line-#{$i} {
      top: ($line-gap + ($line-height * ($i - 1)));
    }
  }
}

@mixin startLoop($start, $end) {
  @for $i from $start through $end {
    &.start-at-#{$i} {
      left: ($day-width * ($i - 1));
    }
  }
}

@mixin eventColors() {
  @for $i from 1 through length($color-list) {
    &.#{nth($color-names, $i)} > a {
      padding-left: 13px;
      padding-right: 10px;
      color: nth($color-list, $i);
      background-color: nth($color-bg-list, $i);
      border-color: nth($color-list, $i);
      border-bottom: none;
      .dot {
        display: none;
      }
    }
  }
}

@mixin tooltipHeadColors() {
  @for $i from 1 through length($color-list) {
    &.#{nth($color-names, $i)} {
      .head {
        padding-left: 10px;
        border-left: 8px solid nth($color-list, $i);
        background-color: nth($color-bg-list, $i);
        color: nth($color-list, $i);
        &:after {
          display: none;
        }
      }
    }
  }
}

@mixin arrowBox($border-color, $size, $x-side, $y-side) {
  &:before {
    #{$x-side}: 100%;
    #{$y-side}: 50%;
    z-index: 1;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba($border-color, 0);
    border-#{$x-side}-color: $border-color;
    border-width: $size;
    margin-#{$y-side}: -$size;
  }
}

@mixin colorizer {
  color: $color-lightest;

  &.green {
    color: $color-green;
  }

  &.yellow {
    color: $color-yellow;
  }

  &.orange {
    color: $color-orange;
  }

  &.red {
    color: $color-red;
  }

  &.purple {
    color: $color-purple;
  }

  &.blue {
    color: $color-blue;
  }

  &.white {
    color: white;
  }

  &.dark {
    &.green {
      color: $color-green-dark;
    }

    &.yellow {
      color: $color-yellow-dark;
    }

    &.orange {
      color: $color-orange-dark;
    }

    &.red {
      color: $color-red-dark;
    }

    &.purple {
      color: $color-purple-dark;
    }

    &.blue {
      color: $color-blue-dark;
    }
  }

  &.light {
    &.green {
      color: $color-green-light;
    }

    &.yellow {
      color: $color-yellow-light;
    }

    &.orange {
      color: $color-orange-light;
    }

    &.red {
      color: $color-red-light;
    }

    &.purple {
      color: $color-purple-light;
    }

    &.blue {
      color: $color-blue-light;
    }
  }
}

@mixin svg-colorizer {
  &.green {
    path, g, rect {
      fill: $color-green;
    }
  }

  &.yellow {
    path, g, rect {
      fill: $color-yellow;
    }
  }

  &.orange {
    path, g, rect {
      fill: $color-orange;
    }
  }

  &.red {
    path, g, rect {
      fill: $color-red;
    }
  }

  &.purple {
    path, g, rect {
      fill: $color-purple;
    }
  }

  &.blue {
    path, g, rect {
      fill: $color-blue;
    }
  }

  &.white {
    path, g, rect {
      fill: white;
    }
  }
}

@mixin colorizer-background-color {
  background-color: $color-lightest;

  &.green {
    background-color: $color-green;
  }

  &.yellow {
    background-color: $color-yellow;
  }

  &.orange {
    background-color: $color-orange;
  }

  &.red {
    background-color: $color-red;
  }

  &.purple {
    background-color: $color-purple;
  }

  &.blue {
    background-color: $color-blue;
  }

  &.white {
    background-color: white;
  }

  &.dark {
    &.green {
      background-color: $color-green-dark;
    }

    &.yellow {
      background-color: $color-yellow-dark;
    }

    &.orange {
      background-color: $color-orange-dark;
    }

    &.red {
      background-color: $color-red-dark;
    }

    &.purple {
      background-color: $color-purple-dark;
    }

    &.blue {
      background-color: $color-blue-dark;
    }
  }

  &.light {
    &.green {
      background-color: $color-green-light;
    }

    &.yellow {
      background-color: $color-yellow-light;
    }

    &.orange {
      background-color: $color-orange-light;
    }

    &.red {
      background-color: $color-red-light;
    }

    &.purple {
      background-color: $color-purple-light;
    }

    &.blue {
      background-color: $color-blue-light;
    }
  }
}

@mixin colorizer-border-color {
  border-color: $color-lightest;

  &.green {
    border-color: $color-green;
  }

  &.yellow {
    border-color: $color-yellow;
  }

  &.orange {
    border-color: $color-orange;
  }

  &.red {
    border-color: $color-red;
  }

  &.purple {
    border-color: $color-purple;
  }

  &.blue {
    border-color: $color-blue;
  }

  &.dark {
    &.green {
      border-color: $color-green-dark;
    }

    &.yellow {
      border-color: $color-yellow-dark;
    }

    &.orange {
      border-color: $color-orange-dark;
    }

    &.red {
      border-color: $color-red-dark;
    }

    &.purple {
      border-color: $color-purple-dark;
    }

    &.blue {
      border-color: $color-blue-dark;
    }
  }

  &.light {
    &.green {
      border-color: $color-green-light;
    }

    &.yellow {
      border-color: $color-yellow-light;
    }

    &.orange {
      border-color: $color-orange-light;
    }

    &.red {
      border-color: $color-red-light;
    }

    &.purple {
      border-color: $color-purple-light;
    }

    &.blue {
      border-color: $color-blue-light;
    }
  }
}

@mixin underliner {
  border-bottom: 1px solid transparent;

  &.green {
    border-bottom-color: $color-green;
  }

  &.yellow {
    border-bottom-color: $color-yellow;
  }

  &.orange {
    border-bottom-color: $color-orange;
  }

  &.red {
    border-bottom-color: $color-red;
  }

  &.purple {
    border-bottom-color: $color-purple;
  }

  &.blue {
    border-bottom-color: $color-blue;
  }
}

@mixin icon-with-dimensions($w, $h, $fill-color) {
  width: $w;
  height: $h;

  & > svg {
    width: $w;
    height: $h;

    path, g, rect {
      fill: $fill-color;
    }
  }
}

@mixin flex-row($justify-content, $align-items) {
  display: flex;
  flex-direction: row;
  justify-content: $justify-content;
  align-items: $align-items;

  & > * {
    order: 1;
  }
}

@mixin flex-column($justify-content, $align-items) {
  display: flex;
  flex-direction: column;
  justify-content: $justify-content;
  align-items: $align-items;

  & > * {
    order: 1;
  }
}

@mixin flex-inline-row($justify-content, $align-items) {
  display: inline-flex;
  flex-direction: row;
  justify-content: $justify-content;
  align-items: $align-items;

  & > * {
    order: 1;
  }
}

@mixin flex-inline-column($justify-content, $align-items) {
  display: inline-flex;
  flex-direction: column;
  justify-content: $justify-content;
  align-items: $align-items;

  & > * {
    order: 1;
  }
}

@mixin single-line() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin center() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin unselectable-class() {
  &.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

@mixin unselectable() {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin appearance($appearance) {
  -moz-appearance: $appearance;
  -webkit-appearance: $appearance;
}

@mixin switch() {
  height: 30px;
  width: 60px;
  position: relative;
  cursor: pointer;

  input {
    @include appearance(none);
    height: 22px;
    width: 24px;
    background: $background-color;
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 100;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:checked {
      left: 32px;
    }

    &:checked + label {
      background: $color-green;
    }
  }

  label {
    background: $color-red;
    height: 30px;
    width: 60px;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: $inset-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }

  &.small {
    transform: scale(0.7);
  }
}

@mixin switch-small() {
  height: 25px;
  width: 50px;
  position: relative;
  cursor: pointer;

  input {
    @include appearance(none);
    height: 17px;
    width: 20px;
    background: $background-color;
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: 100;
    border-radius: 2px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:checked {
      left: 26px;
    }

    &:checked + label {
      background: $color-green;
    }
  }

  label {
    background: $color-red;
    height: 25px;
    width: 50px;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    border-radius: 4px;
    box-shadow: $inset-shadow;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
}

@mixin importButtonWithIcon($front-color, $back-color) {
  border: 1px solid $front-color;
  background-color: $back-color;
  @include flex-row(flex-start, flex-end);

  .icon {
    margin-right: 8px;
    @include icon-with-dimensions(20px, 20px, $front-color);
  }

  p {
    color: $front-color;
    margin: 0;
    padding: 0;
  }

  &:hover {
    background-color: $front-color;
    transform: translateY(2px);

    .icon {
      @include icon-with-dimensions(20px, 20px, $back-color);
    }

    p {
      color: $back-color;
    }
  }

  &.btn-primary, &.btn-danger {
    transition: all 0.2s ease-in-out;
    p {
      font-size: 14px;
      font-weight: 100;
      color: white;
    }
    &:hover {
      background-color: darken($back-color, 5%);
      transform: none;
    }
  }

  &:active {
    transform: translateY(4px);
  }

  &.disabled {
    filter: blur(1px) saturate(0.2) brightness(1.1);
    cursor: default;
  }
}

@mixin buttonHoverEffect($hoverY, $activeY) {
  &:hover {
    transform: translateY($hoverY);
  }

  &:active {
    transform: translateY($activeY);
  }
}

@mixin parametricTitleBar() {
  @include flex-row(flex-start, center);
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px solid $color-lightest;
  position: relative;

  h4 {
    font-weight: 100;
    line-height: 1;
    font-size: 1.7rem;
    margin: 0 0 0 10px;
  }

  .icon {
    position: relative;
    top: 2px;
    cursor: pointer;
    @include icon-with-dimensions(32px, 32px, $color-light);

    &.onright {
      margin-left: auto;
      top: 6px;
    }
  }
}

@mixin parametricGenerateFilterBarWithExcelButtons() {
  @include flex-column(flex-start, flex-start);
  padding: 16px 0 8px 0;
  border-bottom: 1px solid $color-lightest;
  margin-bottom: 16px;
  position: relative;

  .filter-line {
    @include flex-row(flex-start, flex-start);
    width: 100%;
    height: 36px;

    .toleft {
      flex-grow: 1;
    }

    .toright {
      margin-left: auto;
      flex-shrink: 0;
      @include flex-row(flex-start, flex-start);
    }

    .btn {
      margin-right: 8px;
      min-width: auto;

      &:last-of-type {
        margin-right: 0;
      }

      &.green {
        @include importButtonWithIcon($color-green-dark, white);
      }

      &.blue {
        @include importButtonWithIcon($color-blue, white);
      }

      &.purple {
        @include importButtonWithIcon($color-purple, white);
      }
    }
  }

  .tag-line {
    @include flex-row(flex-start, flex-start);
    margin-top: 8px;
    width: 100%;

    .toleft {
      flex-grow: 1;
    }

    .toright {
      margin-left: auto;
      flex-shrink: 0;
      @include flex-row(flex-start, flex-start);
    }

    a {
      color: $color-light;
      margin: 0;
      padding: 0;
      font-size: 0.8rem;
    }
  }
}

@mixin parametricPagination() {
  height: 34px;

  &.top {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &.bottom {
    margin-top: 8px;
    margin-bottom: 0;
  }

  nav ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.8rem;

    a {
      position: relative;
      display: block;
      padding: 0.5rem 0.75rem;
      margin-left: -1px;
      line-height: 1.25;
      color: $color-darkest;
      background-color: #fff;
      border: 1px solid $color-light-border;
    }

    li {
      &.active a {
        color: white;
        pointer-events: none;
        cursor: auto;
        background-color: $color-blue;
        border-color: $color-blue-dark;
      }

      &.disabled a {
        color: $color-light;
        pointer-events: none;
        cursor: auto;
        background-color: $color-lightest;
        border-color: $color-light-border;
      }

      &:first-of-type a {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:last-of-type a {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
}

@mixin parametricInputSelectableDropDown() {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #DCE2EE;
  border-radius: 4px;
  padding-right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  background-color: white;
  box-shadow: $card-higher-shadow;

  ul {
    @include flex-inline-column(flex-start, flex-start);
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      white-space: nowrap;
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid $color-near-white;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: $color-near-white;
      }

      &.selected {
        background-color: $color-blue-light;
        color: white;
      }

      &:after {
        content: "";
        display: block;
      }

      p {
        padding: 8px 16px;
        margin: 0;
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}

@mixin parametricBlocker() {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 300;
  background-color: transparentize($color-near-white, 0.3);
  animation: open-add-new-blocker 500ms ease-in-out forwards;
}

@mixin parametricTable() {
  table {
    position: relative;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    thead {
      background-color: $editor-fill;

      tr {
        th {
          padding: 8px;
          margin: 0;
          font-size: 0.8rem;
          font-weight: 400;
          color: $color-dark;

          &:first-of-type {
            text-align: center;
          }

          &:last-of-type {
            text-align: right;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid lighten($color-lightest, 3);
        font-size: 0.9rem;

        &:last-of-type {
          border-bottom: none;
        }

        th, td {
          padding: 8px;
          margin: 0;

          &:last-of-type {
            border-bottom: none;
          }
        }

        th {
          &:first-of-type {
            text-align: center;
          }
        }

        td {
          &:last-of-type {
            text-align: right;
          }
        }
      }

      tr:nth-of-type(2n) {
        background-color: lighten($editor-fill, 1);
      }
    }
  }
}

@mixin parametricTableColoredButtons() {
  .buttons {
    @include flex-row(flex-end, flex-end);

    .btn {
      min-width: auto;
      max-height: 33px;
      margin-left: 5px;

      &.white .icon {
        @include icon-with-dimensions(20px, 20px, black);
      }

      &.red .icon {
        @include icon-with-dimensions(20px, 20px, white);
      }

      @include colorizer-background-color();
    }
  }
}

@mixin parametricAddNewMultiInputs() {
  &.addnew {
    position: absolute;
    z-index: 301;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation: open-add-new 700ms ease-in-out forwards;
    animation-delay: 300ms;

    .atitle {
      @include flex-row(flex-start, center);

      .icon {
        position: relative;
        top: 2px;
        cursor: pointer;
        margin-left: auto;
        @include icon-with-dimensions(32px, 32px, $color-red-dark);
      }
    }

    .acontent {
      padding-top: 8px;
      @include flex-row(space-between, center);

      .input-with-placeholder {
        flex-grow: 1;

        &:first-of-type {
          margin-right: 8px;
        }

        &:last-of-type {
          margin-left: 8px;
        }
      }

      .has-check {
        flex-grow: 1;
        margin: 0;
        @include flex-row(flex-start, center);

        label {
          margin: 0;
          font-size: 14px;
          cursor: pointer;
          @include flex-row(start, flex-start);
        }

        span {
          color: $dashboard-bar-color;
        }

        input {
          display: none;
          &:checked + .ui-check {
            background-color: $button-default-color;

            div {
              opacity: 1;
            }
          }
        }

        &.radio {
          @include flex-column(start, flex-start);

          .ui-check {
            border-radius: 7px;

            div {
              width: 8px;
              height: 8px;
              margin: 2px;
              border-radius: 4px;
              background: $button-default-color;
            }
          }

          input {
            &:checked + .ui-check {
              background-color: white;
            }
          }
        }

        .ui-check {
          width: 14px;
          height: 14px;
          margin: 4px 4px 4px 0;
          border-radius: 4px;
          border: 1px solid $button-default-color;
          transition: all, .2s ease;
          div {
            width: 12px;
            height: 12px;
            opacity: 0;
            transition: all, .2s ease;
            svg, g {
              width: 12px;
              height: 12px;
              float: left;
              fill: white;
            }
            svg, g, path {
              fill: white;
            }
          }
        }
      }
    }

    .finalbuttons {
      margin-top: 8px;
      text-align: right;

      .btn {
        @include buttonHoverEffect(2px, 4px);
      }
    }
  }
}

@mixin parametricAddNewSingleInput() {
  &.addnew {
    position: absolute;
    z-index: 301;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation: open-add-new 700ms ease-in-out forwards;
    animation-delay: 300ms;

    .atitle {
      @include flex-row(flex-start, center);

      .icon {
        position: relative;
        top: 2px;
        cursor: pointer;
        margin-left: auto;
        @include icon-with-dimensions(32px, 32px, $color-red-dark);
      }
    }

    .acontent {
      padding-top: 8px;
      @include flex-row(space-between, center);

      .input-with-placeholder {
        flex-grow: 1;
      }

      .has-check {
        flex-grow: 1;
        margin: 0;
        @include flex-row(flex-start, center);

        label {
          margin: 0;
          font-size: 14px;
          cursor: pointer;
          @include flex-row(start, flex-start);
        }

        span {
          color: $dashboard-bar-color;
        }

        input {
          display: none;
          &:checked + .ui-check {
            background-color: $button-default-color;

            div {
              opacity: 1;
            }
          }
        }

        &.radio {
          @include flex-column(start, flex-start);

          .ui-check {
            border-radius: 7px;

            div {
              width: 8px;
              height: 8px;
              margin: 2px;
              border-radius: 4px;
              background: $button-default-color;
            }
          }

          input {
            &:checked + .ui-check {
              background-color: white;
            }
          }
        }

        .ui-check {
          width: 14px;
          height: 14px;
          margin: 4px 4px 4px 0;
          border-radius: 4px;
          border: 1px solid $button-default-color;
          transition: all, .2s ease;
          div {
            width: 12px;
            height: 12px;
            opacity: 0;
            transition: all, .2s ease;
            svg, g {
              width: 12px;
              height: 12px;
              float: left;
              fill: white;
            }
            svg, g, path {
              fill: white;
            }
          }
        }
      }
    }

    .finalbuttons {
      margin-top: 8px;
      text-align: right;

      .btn {
        @include buttonHoverEffect(2px, 4px);
      }
    }
  }
}

@mixin parametricExcelImportReport() {
  &.excel-report {
    position: absolute;
    z-index: 301;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    animation: open-add-new 700ms ease-in-out forwards;
    animation-delay: 300ms;

    .atitle {
      @include flex-row(flex-start, center);

      .icon {
        position: relative;
        top: 2px;
        cursor: pointer;
        margin-left: auto;
        @include icon-with-dimensions(32px, 32px, $color-red-dark);
      }
    }

    .acontent {
      margin-top: 8px;
      width: 100%;
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: auto;

      table {
        width: 100%;
        position: relative;
        border-collapse: collapse;
        border: 1px solid $color-light-border;

        thead {
          background-color: lighten($color-light-border, 3);

          tr {
            border-right: 1px solid $color-light-border;
            border-top: 1px solid $color-light-border;
            border-bottom: 1px solid $color-light-border;

            th {
              white-space: nowrap;
              border-right: 1px solid darken($color-light-border, 4);
              padding: 8px;
              margin: 0;
              font-size: 0.8rem;
              font-weight: 400;
              color: $color-darker;

              div {
                position: relative;
                @include flex-row(flex-start, center);

                p {
                  flex-grow: 1;
                  margin: 0 8px 0 0;
                  padding: 0;
                  color: $color-darker;
                }

                .icon-header {
                  &.up {
                    cursor: pointer;
                    display: none;
                    border-left: 1px solid darken($color-light-border, 2);
                    @include icon-with-dimensions(16px, 16px, $color-dark);
                  }

                  &.down {
                    cursor: pointer;
                    display: none;
                    border-left: 1px solid darken($color-light-border, 2);
                    @include icon-with-dimensions(16px, 16px, $color-dark);
                  }

                  &.more {
                    cursor: pointer;
                    border-left: 1px solid darken($color-light-border, 2);
                    @include icon-with-dimensions(24px, 24px, $color-dark);
                  }
                }
              }

              &:last-of-type {
                border-right: none;
              }
            }
          }
        }

        tbody {
          tr {
            white-space: nowrap;
            border-bottom: 1px solid $color-light-border;
            font-size: 0.9rem;

            &:nth-child(even) {
              background-color: lighten($color-light-border, 8);
            }

            &:last-of-type {
              border-bottom: none;
            }

            td {
              border-right: 1px solid $color-light-border;
              padding: 8px;
              margin: 0;
              font-size: 0.8rem;

              &:last-of-type {
                border-right: none;
              }
            }
          }
        }
      }

      .success {
        .icon {
          @include icon-with-dimensions(16px, 16px, $color-green);
        }
      }

      .error {
        .icon {
          @include icon-with-dimensions(16px, 16px, $color-red-dark);
        }
      }
    }
  }
}

@mixin mediaPlanningAddEditInputs() {
  form {
    .acontent {
      padding-top: 20px;
      @include flex-row(space-between, center);

      .input-with-placeholder {
        flex-grow: 1;

        &:first-of-type {
          margin-right: 8px;
        }

        &:last-of-type {
          margin-left: 8px;
        }
      }

      &.has-one-item {
        .input-with-placeholder {
          margin: 0;

          textarea {
            height: 90px;
          }
        }
      }

      &.has-switch {
        justify-content: flex-start;
        .switch + div {
          p {
            margin: 0;
            padding-left: 24px;
            font-size: 14px;
            line-height: 20px;
            color: $color-star-gray;
            font-weight: 400;
            &:first-child {
              color: $color-near-black;
            }
          }
        }
      }

      &.top-aligned {
        align-items: flex-start;
      }

      &.info {
        padding: 0;
        @include flex-row(start, flex-start);

        div[role="icon"] {
          width: 18px;
          margin-right: 4px;

          svg, g {
            width: 18px;
            height: 38px;
          }
        }

        span {
          line-height: 38px;
          font-size: 13px;
          color: $color-star-gray;
        }
      }

      &.has-check {
        @include flex-row(flex-start, center);
        border: 1px solid $color-light-border;
        border-radius: 4px;
        padding: 16px;
        margin-top: 20px;

        label {
          margin: 0;
          font-size: 14px;
          cursor: pointer;
          @include flex-row(flex-start, center);
        }

        span {
          color: $dashboard-bar-color;
        }

        input {
          display: none;
          &:checked + .ui-check {
            background-color: $button-default-color;

            div {
              opacity: 1;
            }
          }
        }

        &.radio {
          @include flex-column(start, flex-start);

          .ui-check {
            border-radius: 7px;

            div {
              width: 8px;
              height: 8px;
              margin: 2px;
              border-radius: 4px;
              background: $button-default-color;
            }
          }

          input {
            &:checked + .ui-check {
              background-color: white;
            }
          }
        }

        .ui-check {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          border-radius: 4px;
          border: 1px solid $button-default-color;
          transition: all, .2s ease;
          div {
            width: 12px;
            height: 12px;
            opacity: 0;
            transition: all, .2s ease;
            svg, g {
              width: 12px;
              height: 12px;
              float: left;
              fill: white;
            }
            svg, g, path {
              fill: white;
            }
          }
        }
      }
    }

    .final-buttons {
      border-top: 1px solid $dashboard-bar-hover-color;
      margin-top: 20px;
      padding-top: 20px;
      @include flex-row(flex-end, flex-end);

      .btn {
        margin-left: 16px;

        &.btn-primary {
          @include importButtonWithIcon($color-blue, $color-blue);
          justify-content: center;
        }

        &.btn-danger {
          @include importButtonWithIcon($color-error, $color-error);
          justify-content: center;
        }
      }
    }
  }
}

@mixin mediaPlanningAddEditInputsNoForm() {
  .acontent {
    @include flex-row(space-between, center);

    &:first-of-type {
      padding-top: 0;
    }

    .input-with-placeholder {
      flex-grow: 1;

      &:first-of-type {
        margin-right: 8px;
      }

      &:last-of-type {
        margin-left: 8px;
      }
    }

    &.has-one-item {
      .input-with-placeholder {
        margin: 0;

        textarea {
          height: 90px;
        }
      }
    }

    &.has-switch {
      justify-content: flex-start;
    }

    &.info {
      padding: 0;
      @include flex-row(start, flex-start);

      div[role="icon"] {
        width: 18px;
        margin-right: 4px;

        svg, g {
          width: 18px;
          height: 38px;
        }
      }

      span {
        line-height: 38px;
        font-size: 13px;
        color: $color-star-gray;
      }
    }

    &.has-check {
      @include flex-row(flex-start, center);
      border: 1px solid $color-light-border;
      border-radius: 4px;
      padding: 16px;
      margin-top: 20px;

      label {
        margin: 0;
        font-size: 14px;
        cursor: pointer;
        @include flex-row(flex-start, center);
      }

      span {
        color: $dashboard-bar-color;
      }

      input {
        display: none;
        &:checked + .ui-check {
          background-color: $button-default-color;

          div {
            opacity: 1;
          }
        }
      }

      &.radio {
        @include flex-column(start, flex-start);

        .ui-check {
          border-radius: 7px;

          div {
            width: 8px;
            height: 8px;
            margin: 2px;
            border-radius: 4px;
            background: $button-default-color;
          }
        }

        input {
          &:checked + .ui-check {
            background-color: white;
          }
        }
      }

      .ui-check {
        width: 14px;
        height: 14px;
        margin-right: 8px;
        border-radius: 4px;
        border: 1px solid $button-default-color;
        transition: all, .2s ease;
        div {
          width: 12px;
          height: 12px;
          opacity: 0;
          transition: all, .2s ease;
          svg, g {
            width: 12px;
            height: 12px;
            float: left;
            fill: white;
          }
          svg, g, path {
            fill: white;
          }
        }
      }
    }
  }

  .final-buttons {
    border-top: 1px solid $dashboard-bar-hover-color;
    margin-top: 20px;
    padding-top: 20px;
    @include flex-row(flex-end, flex-end);

    .btn {
      margin-left: 16px;

      &.btn-primary {
        @include importButtonWithIcon($color-blue, $color-blue);
        justify-content: center;
      }

      &.btn-danger {
        @include importButtonWithIcon($color-error, $color-error);
        justify-content: center;
      }
    }
  }
}

@mixin mediaPlanningSiteAddAddEditInputs() {
  .site-form {
    margin-bottom: 60px;

    .acontent {
      padding-top: 20px;
      @include flex-row(space-between, center);

      .input-with-placeholder {
        flex-grow: 1;

        &:first-of-type {
          margin-right: 8px;
        }

        &:last-of-type {
          margin-left: 8px;
        }
      }

      &.has-one-item {
        .input-with-placeholder {
          margin: 0;

          textarea {
            height: 90px;
          }
        }
      }

      &.info {
        padding: 0;
        @include flex-row(start, flex-start);

        div[role="icon"] {
          width: 18px;
          margin-right: 4px;

          svg, g {
            width: 18px;
            height: 38px;
          }
        }

        span {
          line-height: 38px;
          font-size: 13px;
          color: $color-star-gray;
        }
      }

      &.has-check {
        @include flex-row(flex-start, center);
        border: 1px solid $color-light-border;
        border-radius: 4px;
        padding: 16px;
        margin-top: 20px;

        label {
          margin: 0;
          font-size: 14px;
          cursor: pointer;
          @include flex-row(flex-start, center);
        }

        span {
          color: $dashboard-bar-color;
        }

        input {
          display: none;
          &:checked + .ui-check {
            background-color: $button-default-color;

            div {
              opacity: 1;
            }
          }
        }

        &.radio {
          @include flex-column(start, flex-start);

          .ui-check {
            border-radius: 7px;

            div {
              width: 8px;
              height: 8px;
              margin: 2px;
              border-radius: 4px;
              background: $button-default-color;
            }
          }

          input {
            &:checked + .ui-check {
              background-color: white;
            }
          }
        }

        .ui-check {
          width: 14px;
          height: 14px;
          margin-right: 8px;
          border-radius: 4px;
          border: 1px solid $button-default-color;
          transition: all, .2s ease;
          div {
            width: 12px;
            height: 12px;
            opacity: 0;
            transition: all, .2s ease;
            svg, g {
              width: 12px;
              height: 12px;
              float: left;
              fill: white;
            }
            svg, g, path {
              fill: white;
            }
          }
        }
      }

      button {
        margin-left: 30px;
      }
    }

    .final-buttons {
      border-top: 1px solid $dashboard-bar-hover-color;
      margin-top: 20px;
      padding-top: 20px;
      @include flex-row(flex-end, flex-end);

      .btn {
        margin-left: 16px;

        &.btn-primary {
          @include importButtonWithIcon($color-blue, $color-blue);
          justify-content: center;
        }

        &.btn-danger {
          @include importButtonWithIcon($color-error, $color-error);
          justify-content: center;
        }
      }
    }
  }
}

@mixin mediaPlanningInputSelectableDropDown() {
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #DCE2EE;
  border-radius: 4px;
  padding-right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  background-color: white;
  box-shadow: $card-higher-shadow;

  ul {
    @include flex-inline-column(flex-start, flex-start);
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      white-space: nowrap;
      cursor: pointer;
      width: 100%;
      border-bottom: 1px solid $color-near-white;

      &:last-of-type {
        border-bottom: none;
      }

      &:hover {
        background-color: $color-near-white;
      }

      &.selected {
        background-color: $color-blue-light;
        color: white;
      }

      &:after {
        content: "";
        display: block;
      }

      p {
        padding: 8px 16px;
        margin: 0;
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}

@mixin mediaPlanningTitleBar() {
  @include flex-row(flex-start, center);
  padding: 16px 0;
  margin: 0;
  border-bottom: 1px solid $color-lightest;
  position: relative;

  h4 {
    font-weight: 300;
    line-height: 1;
    font-size: 1.7rem;
    margin: 0 0 0 10px;
    flex-grow: 1;

    &:first-child {
      margin: 0;
    }
  }

  .icon {
    position: relative;
    top: 2px;
    cursor: pointer;
    @include icon-with-dimensions(32px, 32px, $color-light);
  }

  .onright {
    margin-left: auto;
    text-align: right;
    h3 {
      font-size: 1.3rem;
      font-weight: 600;
      margin: 0;
      padding: 0;
    }

    h5 {
      font-size: 1.1rem;
      font-weight: 300;
      margin: 0;
      padding: 0;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .input-with-placeholder {
    position: relative;
  }

  .a-dropdown-popup {
    z-index: 4;
    @include mediaPlanningInputSelectableDropDown();

    ul li p {
      font-size: 0.8rem;
    }
  }

}

@mixin mediaPlanningTags() {
  min-height: 32px;
  @include flex-row(flex-start, flex-start);
  flex-wrap: wrap;

  & > .a-filter {
    @include flex-row(flex-start, center);
    padding: 2px;
    margin-right: 4px;
    margin-bottom: 4px;
    border: 1px solid lighten($color-blue-light, 40);
    background-color: lighten($color-blue-light, 50);
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    animation: media-planning-appear-from-right 200ms ease-in-out forwards;

    p {
      margin: 0 8px 0 6px;
      padding: 0;
      font-weight: 400;
      font-size: 0.9rem;
      color: darken($color-blue-light, 20);
    }

    .icon {
      @include icon-with-dimensions(18px, 18px, $color-red);
    }

  }
}

@mixin mediaPlanningFilterSystem() {
  border: 1px solid $color-lightest;
  border-radius: 4px;
  height: 36px;
  min-width: 60%;
  @include flex-inline-row(flex-start, center);

  .filter-left {
    flex-grow: 1;
    flex-shrink: 0;
    height: 100%;
    padding: 0 16px;
    cursor: pointer;
    border-right: 1px solid $color-lightest;
    @include flex-row(flex-start, center);

    p {
      margin: 0 8px 0 0;
      padding: 0;
      color: $color-darker;
      font-weight: 400;
      font-size: 0.9rem;
    }

    .icon {
      margin-left: auto;
      @include icon-with-dimensions(20px, 20px, $color-lightest);
    }
  }

  .filter-right {
    flex-grow: 4;
    flex-shrink: 0;

    .input-with-placeholder {
      border: none;
    }
  }

  .a-dropdown-popup {
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #DCE2EE;
    border-radius: 4px;
    padding-right: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 200px;
    background-color: white;
    opacity: 0;
    box-shadow: $card-higher-shadow;
    z-index: 2;

    ul {
      @include flex-inline-column(flex-start, flex-start);
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        white-space: nowrap;
        cursor: pointer;
        width: 100%;
        border-bottom: 1px solid $color-near-white;

        &:last-of-type {
          border-bottom: none;
        }

        &:hover {
          background-color: $color-near-white;
        }

        &:after {
          content: "";
          display: block;
        }

        p {
          color: $color-darker;
          padding: 8px 16px;
          margin: 0;
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
  }
}

@mixin mediaPlanningPagination() {
  position: relative;
  @include flex-row(flex-start, center);

  &.top {
    margin-top: 0;
    margin-bottom: 8px;
  }

  &.bottom {
    margin-top: 8px;
    margin-bottom: 0;
  }

  nav {
    display: flex;
  }

  nav ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.8rem;

    a {
      position: relative;
      display: block;
      padding: 0.2rem 0.5rem;
      line-height: 1.2rem;
      color: $color-darkest;
      background-color: #fff;
      border: 1px solid lighten($color-light-border, 5);
      border-radius: 4px;
    }

    li {
      margin: 0 4px;

      &.active a {
        pointer-events: none;
        cursor: auto;
        color: white;
        background-color: $color-blue;
        border-color: $color-blue-dark;
      }

      &.disabled a {
        pointer-events: none;
        cursor: auto;
        color: $color-light;
        background-color: lighten($color-lightest, 5);
        border-color: $color-light-border;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .header-manager {
    margin-left: auto;
    @include flex-row(flex-end, center);
    align-self: flex-end;
  }

  .header-manager .info {
    color: $color-darker;
    font-weight: 500;
    font-size: 0.9rem;
    margin-right: 8px;

    &.no-margin {
      margin-right: 0;
    }
  }

  .header-manager .table-width {
    padding: 2px;
    border: 1px solid $color-light-border;
    background-color: white;
    border-radius: 2px;

    .icon {
      cursor: pointer;
      @include icon-with-dimensions(18px, 18px, $color-dark);
    }
  }

  .header-manager .multi-action-item {
    padding: 2px;
    border: 1px solid $color-light-border;
    background-color: white;
    border-radius: 22px;
    margin-right: 8px;

    .icon {
      cursor: pointer;
      @include icon-with-dimensions(18px, 18px, $color-dark);
    }

    &:hover {
      background-color: $color-light-border;
    }

    animation-fill-mode: forwards;
  }

  .header-manager .headers {
    padding: 2px;
    border: 1px solid $color-light-border;
    background-color: white;
    border-radius: 2px;
    margin-right: 8px;
    cursor: pointer;

    .icon {
      @include icon-with-dimensions(18px, 18px, $color-dark);
    }
  }
}

$table-left-shadow: 5px 0 20px 0 rgba(35, 34, 135, 0.15);
$table-right-shadow: -5px 0 20px 0 rgba(35, 34, 135, 0.15);
$table-bottom-shadow: 0 5px 20px 0 rgba(35, 34, 135, 0.4);

@mixin mediaPlanningTable() {
  @include flex-column(flex-start, flex-start);

  .tables {
    margin-top: 8px;
    width: 100%;
    @include flex-row(flex-start, flex-start);

    // Default table state
    table {
      position: relative;
      border-collapse: collapse;
      border-bottom: 1px solid $color-light-border;

      thead {
        background-color: lighten($color-light-border, 3);

        tr {
          border-right: 1px solid $color-light-border;
          border-top: 1px solid $color-light-border;
          border-bottom: 1px solid $color-light-border;

          th {
            white-space: nowrap;
            border-right: 1px solid darken($color-light-border, 4);
            padding: 8px;
            margin: 0;
            font-size: 0.8rem;
            font-weight: 400;
            color: $color-darker;
            min-width: 60px;
            height: 41px;

            div {
              position: relative;
              @include flex-row(flex-start, center);

              p {
                white-space: pre;
                flex-grow: 1;
                margin: 0 8px 0 0;
                padding: 0;
                color: $color-darker;
              }

              .icon-header {
                &.up {
                  cursor: pointer;
                  display: none;
                  border-left: 1px solid darken($color-light-border, 2);
                  @include icon-with-dimensions(16px, 16px, $color-dark);
                }

                &.down {
                  cursor: pointer;
                  display: none;
                  border-left: 1px solid darken($color-light-border, 2);
                  @include icon-with-dimensions(16px, 16px, $color-dark);
                }

                &.more {
                  cursor: pointer;
                  border-left: 1px solid darken($color-light-border, 2);
                  @include icon-with-dimensions(24px, 24px, $color-dark);
                }
              }
            }

            &:last-of-type {
              border-right: none;
            }
          }
        }
      }

      tbody {
        tr {
          white-space: nowrap;
          border-bottom: 1px solid $color-light-border;
          font-size: 0.9rem;

          &:nth-child(even) {
            background-color: lighten($color-light-border, 8);
          }

          &:last-of-type {
            border-bottom: none;
          }

          td {
            border-right: 1px solid $color-light-border;
            padding: 8px;
            margin: 0;
            height: 42px;

            &:last-of-type {
              border-right: none;
            }
          }
        }
      }
    }

    // Locked table
    table.locked {
      flex-shrink: 0;
      &.left {
        border-left: 1px solid $color-light-border;

        thead tr th {
          &:first-of-type {
            font-weight: bolder;
          }
        }

        tbody tr td {
          &:first-of-type {
            font-weight: bolder;
          }
        }
      }

      &.right {
        border-right: 1px solid $color-light-border;
        .has-check {
          margin: 0 10px 0 5px;
        }
      }
    }

    // Hidden table
    table.hidden {
      display: none;
    }

    // Left table
    table.left {
      position: relative;
      z-index: 1;
      box-shadow: $table-left-shadow;
    }

    // Right table
    table.right {
      position: relative;
      z-index: 1;
      box-shadow: $table-right-shadow;

      thead tr th div p {
        color: lighten($color-light-border, 3);
      }
    }

    // Middle table
    div.locked {
      min-height: 100%;
      flex-grow: 1;
      overflow-x: auto;
      overflow-y: hidden;

      table {
        border-left: 1px solid $color-light-border;
        border-right: 1px solid $color-light-border;
        min-width: 100%;

        tr .icon {
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          @include icon-with-dimensions(18px, 18px, $color-dark);
          @include svg-colorizer();
        }
      }
    }

    // Table inner child positioning
    table {
      .left {
        text-align: left;
      }

      .right {
        text-align: right;
      }

      .center {
        text-align: center;
      }
    }

    // Action button defaults
    table tbody td.action-buttons {
      height: 41px;
      @include flex-row(flex-end, center);

      .a-button {
        padding: 0 2px;
        border: 1px solid $color-light-border;
        border-right: none;
        display: block;
        background-color: white;

        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-left: 1px solid $color-light-border;
        }

        &:last-of-type {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-right: 1px solid $color-light-border;
        }

        &:hover {
          background-color: $color-lightest;
        }

        &.alone {
          border-radius: 4px;
          margin-right: 8px;
          border: 1px solid $color-light-border;

          & + a {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-left: 1px solid $color-light-border;
          }
        }

        .icon {
          display: block;
          @include icon-with-dimensions(28px, 28px, $color-dark);
          @include svg-colorizer();
        }
      }
    }
  }
}

@mixin mediaPlanningTableSortMenu() {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4px;
  border: 1px solid $color-light-border;
  border-radius: 4px;
  box-shadow: $table-bottom-shadow;
  background-color: white;
  z-index: 3;
  overflow: hidden;
  @include flex-inline-column(flex-start, flex-start);

  .child {
    width: 100%;
    padding: 8px 8px;
    cursor: pointer;
    border-bottom: 1px solid $color-light-border;
    @include flex-row(flex-start, center);

    &:last-of-type {
      border-bottom: none;
    }

    p {
      padding: 0;
      margin: 0 8px 0 0;
      color: $color-darkest;
      font-size: 0.9rem;
      font-weight: normal;
    }

    .icon {
      border-radius: 4px;
      margin-right: 8px;
      @include icon-with-dimensions(24px, 24px, $color-dark);
    }

    &.active {
      p {
        color: $color-blue;
      }

      .icon {
        @include icon-with-dimensions(24px, 24px, $color-blue);
      }
    }

    &.passive {
      p {
        color: $color-light;
      }

      .icon {
        @include icon-with-dimensions(24px, 24px, $color-light);
      }
    }
  }
}

@mixin mediaPlanningTableHeaderMenu() {
  position: absolute;
  top: 0;
  left: 0;
  padding: 8px;
  border: 1px solid $color-light-border;
  border-radius: 4px;
  box-shadow: $table-bottom-shadow;
  background-color: white;
  z-index: 3;
  max-height: 400px;
  min-width: 270px;
  overflow-x: hidden;
  overflow-y: auto;
  @include flex-inline-column(flex-start, flex-start);

  .child {
    width: 100%;
    padding: 8px 8px;
    border-bottom: 1px solid $color-light-border;
    background-color: white;
    @include flex-row(flex-start, center);

    &.is-moving {
      border: 1px solid $color-light-border;
    }

    &:last-of-type {
      border-bottom: none;
    }

    p {
      padding: 0;
      margin: 0 8px 0 0;
      font-size: 0.87rem;
      font-weight: normal;
      white-space: nowrap;
      flex-grow: 1;
      cursor: pointer;
      @include colorizer();
      color: $color-light;
    }

    .icon {
      margin-right: 8px;
      flex-shrink: 0;
      cursor: pointer;
      @include svg-colorizer();
      @include icon-with-dimensions(20px, 20px, $color-lightest);

      &:last-of-type {
        margin-left: 0;
      }
    }
  }

  .child.dummy-box {
    * {
      opacity: 0.3;
    }
  }

  .child.onanimate {
    animation: media-planning-header-drag-drop 500ms ease-in-out forwards;
    animation-delay: 100ms;
  }
}

@mixin mediaPlanningPopupDateSelector() {
  .popup-date-selector {
    position: absolute;
    top: 100px;
    left: 100px;
    padding: 8px;
    border: 1px solid $color-lightest;
    border-radius: 4px;
    overflow: hidden;
    background-color: $background-color;
    width: 260px;
    height: 200px;
    box-shadow: $table-bottom-shadow;
    @include flex-column(space-between, stretch);
    @include unselectable();

    & > div {
      @include flex-row(space-between, center);
      width: 100%;
      height: 24px;
    }

    .title {
      position: relative;
      background-color: lighten($color-lighter, 15);
      border-radius: 4px;
      margin-bottom: 4px;
      @include flex-row(space-between, center);

      .current-month {
        margin: 0;
        font-weight: normal;
        font-size: 0.8rem;
        flex-grow: 1;
        text-align: center;
      }

      .icon {
        cursor: pointer;
        @include icon-with-dimensions(16px, 16px, $color-darkest);
      }
    }

    .weekdays {
      height: auto;
      margin-bottom: 2px;

      p {
        margin: 0;
        font-weight: 200;
        font-size: 0.6rem;
        color: $color-darkest;
        flex-basis: 100%;
        text-align: center;
      }
    }

    .aweek {
      p {
        font-weight: bold;
        font-size: 0.7rem;
        color: $color-darkest;
        margin: 0;
        cursor: pointer;
        border-radius: 4px;
        flex-basis: 100%;
        text-align: center;

        &.passive {
          font-weight: bold;
          color: $color-lightest;
        }

        &.today {
          background-color: $color-lightest;
        }

        &.selected {
          background-color: $color-darkest;
          color: $background-color;
        }

        &.disabled {
          background-color: transparent;
          color: transparentize($color-red, 0.95);
          cursor: default;
        }
      }
    }
  }
}

@mixin billingUiCheck () {
  .has-check {
    flex-grow: 1;
    margin: 0;
    @include flex-row(center, center);

    label {
      margin: 0;
      font-size: 14px;
      cursor: pointer;
      @include flex-row(start, flex-start);
    }

    span {
      color: $dashboard-bar-color;
    }

    input {
      display: none;
      &:checked + .ui-check {
        background-color: $button-default-color;

        div {
          opacity: 1;
        }
      }
    }

    &.radio {
      @include flex-column(start, flex-start);

      .ui-check {
        border-radius: 7px;

        div {
          width: 8px;
          height: 8px;
          margin: 2px;
          border-radius: 4px;
          background: $button-default-color;
        }
      }

      input {
        &:checked + .ui-check {
          background-color: white;
        }
      }
    }

    .ui-check {
      width: 14px;
      height: 14px;
      margin: 4px 4px 4px 0;
      border-radius: 4px;
      border: 1px solid $button-default-color;
      transition: all, .2s ease;
      div {
        width: 12px;
        height: 12px;
        opacity: 0;
        transition: all, .2s ease;
        svg, g {
          width: 12px;
          height: 12px;
          float: left;
          fill: white;
        }
        svg, g, path {
          fill: white;
        }
      }
    }
  }
}

@mixin MediaplanLinesHeadTotals () {

  margin-bottom: 20px;

  .d-flex > .head-cell {
    padding: 8px 16px;
    background-color: $editor-fill;
    border: 1px solid #DCE2EE;
    border-left: none;
    color: $dashboard-bar-color;
    position: relative;
    transition: all .3s ease;
    &:first-child {
      border-left: 1px solid #DCE2EE;
    }
    & > div {
      width: 100%;
      display: inline-block;
    }
    .key {
      // max-width: 80%;
      max-width: 100%;
      line-height: 32px;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
    }
    .value {
      max-width: 100%;
      line-height: 32px;
      font-size: 18px;
      font-weight: 700;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
      input {
        max-width: 130px;
        height: 32px;
        font-size: 18px;
        font-weight: 700;
        background: $editor-fill;
        border: 1px solid $editor-fill;
        border: none;
        outline: none;
        color: $dashboard-bar-color;
        transition: all .2s ease;
        &:focus {
          background-color: #FFF;
          border: 1px solid #DCE2EE;
          outline: none;
        }
      }
    }
    &:nth-child(2) {
      .value {
        max-width: 80%;
      }
    }
    .btn-visible {
      width: 32px;
      height: 32px;
      display: none;
      position: absolute;
      top: 8px;
      right: 4px;
      .icon {
        width: 100%;
        height: 100%;
        pointer-events: none;
        &[data-icon="show"] {
          width: 24px;
          height: 24px;
          margin-top: 4px;
          display: none;
        }
        svg, g, path {
          width: 100%;
          height: 100%;
          fill: $dashboard-bar-color;
        }
      }
    }
    &.hide {
      height: 48px;
      background-color: #fff;
      color: $color-star-gray;
      .value {
        display: none;
      }
      .btn-visible {
        .icon[data-icon="hide"] {
          display: none;
        }
        .icon[data-icon="show"] {
          display: block;
        }
      }
    }
  }

}

@keyframes media-planning-appear-from-right {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes media-planning-appear-from-bottom {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes media-planning-disappear {
  to {
    width: 0;
    opacity: 0;
    margin-right: 0;
  }
}

@keyframes media-planning-header-drag-drop {
  from {
    background-color: $color-blue-light;
  }
  to {
    background-color: white;
  }
}
