#login-warning {
  width: 430px;

  .flex-column {
    width: 100%;
  }

  .flex-row {
    width: 100%;
  }

  .icon {
    @include icon-with-dimensions(100px, 100px, $color-warning);
  }

  .info {
    order: 0;
    @include icon-with-dimensions(32px, 32px, $color-darkest);
  }

  h2, p {
    text-align: center;
  }

  a {
    margin-left: 10px;
  }
}
